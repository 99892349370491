import "lazysizes"
import "picturefill"
// import lpForm from './utill/lp-form'
// import svg4everybody from "svg4everybody"

// lpForm();



const scrollEvt = () => {
  const windowWidth = $(window).width();
  const windowSm = 768;
  const ua = navigator.userAgent;

  if (windowWidth > windowSm) {
    $(window).on("scroll", function() {
      if(ua.indexOf('Trident') === -1) {
        if ($(this).scrollTop() > 100 ) {

          $("header").css({
            "height": 75
          });
          $(".header__contact").css({
            "border-bottom": "75px solid #2B2F3B"
          });
          $(".header__contact__txt").css({
            "padding-top": 75
          });
        } else {
          $("header").css({
            "height": 100
          });
          $(".header__contact").css({
            "border-bottom": "100px solid #2B2F3B"
          });
          $(".header__contact__txt").css({
            "padding-top": 100
          });
        }
      }
    });    
  }
}
// 子パネル (li.cell) の数だけ空の子パネル (li.cell.is-empty) を追加する。
// var $grid = $('.system__box__list'),   
// emptyCells = [],
// i;

// for (i = 0; i < $grid.find('.system__box__item').length; i++) {
//   emptyCells.push($('<div>', { class: 'system__box__item is-empty' }));
// }
// $grid.append(emptyCells);

$('.contentInterior__list--sp').slick({
  // slidesToShow: 2,
  // slidesToScroll: 1,
  infinite: true,
});  

// 続きを見る
var itemHeights = []; //
$(function(){
  $(".grad-item").each(function(){ //ターゲット(縮めるアイテム)
    var thisHeight = $(this).height(); //ターゲットの高さを取得
    itemHeights.push(thisHeight); //それぞれの高さを配列に入れる
    $(this).addClass("is-hide"); //CSSで指定した高さにする
  });
});

$(".grad-trigger").click(function(){
  var index = $(this).index(".grad-trigger"); //トリガーが何個目か
  var addHeight = itemHeights[index]; //個数に対応する高さを取得
  $(this).fadeOut().addClass("is-show").next().animate({height: addHeight},200).removeClass("is-hide"); //高さを元に戻す
});

//アコーディオン
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.system__box,+.system__box--s", this).slideToggle();
    $(this).toggleClass("is-active")
    return false;
  });
});

//ヘッダーメニュー
$('.header__btn').on('click', function () {
  // $('html').toggleClass('scroll-prevent')
  if ($(this).hasClass('is-active')) {
    $(this).removeClass('is-active');
    $('#nav-list').slideUp();
  } else {
    $(this).addClass('is-active');
    $('#nav-list').slideDown();
  }
})

let timer = null;
$(window).on('scroll', function () {
  clearTimeout(timer);
  timer = setTimeout(function () {
    if ($(this).scrollTop() > 100) {
      $(".ctaFixed").addClass("is-fixed")
    } else {
      $(".ctaFixed").removeClass("is-fixed")
    }
  }, 10);
  timer = setTimeout(function () {
    if ($(this).scrollTop() > 800) {
      console.log("kita");
      
      $(".ctaFixed--pc").addClass("is-fixed")

    } else {
      $(".ctaFixed--pc").removeClass("is-fixed")
    }
  }, 10);    
});

$('.ctaFixed__close').on('click', function () {
  $('.ctaFixed--pc').css('display','none');
})

$('a[href^="#"]').click(function () {
  var speed = 500;
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});


(() => {
  scrollEvt()
})()